import { trapFocus } from "@grrr/utils";

const BODY_SELECTOR = document.querySelector("body");
const TEMPLATE_SELECTOR = ".js-remembrance-modal-template";

const createModal = () => {
  const modal = document.createElement("div");
  modal.className = "remembrance-modal js-remembrance-modal";
  modal.setAttribute("role", "dialog");
  modal.appendChild(
    document.querySelector(TEMPLATE_SELECTOR).content.cloneNode(true)
  );

  return modal;
};

const populateModal = (el, modal) => {
  // This needs to work in a different way, but for now:
  const PLACEHOLDER_NODES = `
    .js-remembrance-modal-name,
    .js-remembrance-modal-year,
    .js-remembrance-modal-line-1,
    .js-remembrance-modal-line-2,
    .js-remembrance-modal-line-3,
    .js-remembrance-modal-image
  `;
  const placeholders = [...document.querySelectorAll(PLACEHOLDER_NODES)];
  placeholders.forEach((placeholder) => {
    placeholder.innerHTML = "";
  });

  const namePlaceholder = modal.querySelector(".js-remembrance-modal-name");
  const yearPlaceholder = modal.querySelector(".js-remembrance-modal-year");
  const lineOnePlaceholder = modal.querySelector(
    ".js-remembrance-modal-line-1"
  );
  const lineTwoPlaceholder = modal.querySelector(
    ".js-remembrance-modal-line-2"
  );
  const lineThreePlaceholder = modal.querySelector(
    ".js-remembrance-modal-line-3"
  );
  const imagePlaceholder = modal.querySelector(".js-remembrance-modal-image");

  const id = el.getAttribute("data-trap-id");
  const name = el.getAttribute("data-name");
  const year = el.getAttribute("data-year");
  const lineOne = el.getAttribute("data-line-1");
  const lineTwo = el.getAttribute("data-line-2");
  const lineThree = el.getAttribute("data-line-3");

  namePlaceholder.innerHTML = name;
  if (year) {
    yearPlaceholder.innerHTML = `(${year})`;
  }
  if (lineOne) {
    lineOnePlaceholder.innerHTML = lineOne;
  }
  if (lineTwo) {
    lineTwoPlaceholder.innerHTML = lineTwo;
  }
  if (lineThree) {
    lineThreePlaceholder.innerHTML = lineThree;
  }

  if (el.hasAttribute("data-image")) {
    const image = document.createElement("img");
    const imageSrc = el.getAttribute("data-image");
    image.setAttribute("src", imageSrc);
    image.setAttribute("alt", "");
    imagePlaceholder.appendChild(image);
    modal.classList.add("remembrance-modal--has-image");
  } else {
    imagePlaceholder.parentNode.removeChild(imagePlaceholder);
  }
  modal
    .querySelector(".js-remembrance-modal-close-button")
    .setAttribute("data-trap-id", id);
};

const traps = {};

const trap = (id, modal) => {
  traps[id] = trapFocus(modal);
};

const releaseTrap = (id) => traps[id].release();

const toggleModal = (shouldShow, el) => {
  const modal = createModal();
  const identifier = el.getAttribute("data-trap-id");
  BODY_SELECTOR.classList.toggle("has-active-modal", shouldShow);
  if (shouldShow === true) {
    populateModal(el, modal);
    BODY_SELECTOR.insertBefore(modal, BODY_SELECTOR.firstChild);
    trap(identifier, modal);
  } else {
    releaseTrap(identifier);
  }
  document
    .querySelector(".js-remembrance-modal")
    .setAttribute("aria-hidden", !shouldShow);
};

const addKeyListener = (listener) =>
  document.addEventListener("keydown", listener);
const removeKeyListener = (listener) =>
  document.removeEventListener("keydown", listener);

const createEscListener = (el) => {
  const escListener = (e) => {
    if (e.keyCode === 27) {
      toggleModal(false, el);
      removeKeyListener(escListener);
    }
  };
  return escListener;
};

export const closeHandler = (el) => {
  toggleModal(false, el);
};

export const handler = (el, e) => {
  toggleModal(true, el);
  addKeyListener(createEscListener(el));
};
