/* eslint-disable import/no-cycle */

import { preventingDefault } from "@grrr/utils";
import { enhance } from "@grrr/hansel";
import { ENHANCERS } from "../main";

const PasswordProtectionForm = (form) => {
  const errorElement = form.querySelector('[role="alert"]');
  const passwordInput = form.querySelector('[name="password"]');
  const localizations = window.PASSWORD_PROTECTION_LOCALIZATION || [];

  const localizeMessage = (message) => {
    const localizedMessage = localizations.find(
      (localization) => localization.message === message
    );
    return localizedMessage ? localizedMessage.translation : message;
  };

  /**
   * Set or reset the error message.
   */
  const setErrorMessage = (message) => {
    errorElement.textContent = localizeMessage(message);
    errorElement.setAttribute("aria-hidden", !message);
    passwordInput.setAttribute(
      "aria-describedby",
      message ? errorElement.id : ""
    );
    passwordInput.setAttribute("aria-invalid", message);
  };
  const resetErrorMessage = () => setErrorMessage("");

  /**
   * Replace all `.admin` URLs if we're not on the admin page.
   * All URLs are rewritten during the static site generator, but since the
   * password protected content is encrypted, it can't be done there.
   */
  const normalizeAdminUrls = (html) => {
    if (window.location.hostname.indexOf("admin.") === -1) {
      return html.replace(new RegExp("admin.", "g"), "");
    }
    return html;
  };

  /**
   * Fetch decrypted content from microservice, given the password is correct.
   */
  const fetchContent = () => {
    return new Promise((resolve, reject) => {
      fetch(form.getAttribute("action"), {
        method: form.getAttribute("method"),
        body: new FormData(form),
      })
        .then((response) => {
          if (response.status !== 200) {
            return response
              .json()
              .then((json) =>
                reject(json.error ? json.error : JSON.stringify(json))
              );
          }
          return response.json().then((json) => resolve(json.result));
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Dynamically added scripts in HTML nodes are not evaluated, so we'll have
   * to re-add them manually. Note that when adding multiple scripts,
   * they will be executed asynchronously. It's easier to bundle them if they're
   * dependent on each other.
   */
  const evaluateScripts = (rootNode) => {
    const scriptNodes = rootNode.querySelectorAll("script");
    [...scriptNodes].forEach((scriptNode) => {
      const script = document.createElement("script");
      // External scripts.
      if (scriptNode.src) {
        script.src = scriptNode.src;
        // Lazy-loaded external scripts.
      } else if (scriptNode.getAttribute("data-src")) {
        script.src = scriptNode.getAttribute("data-src");
        // Inline scripts.
      } else {
        script.innerHTML = scriptNode.innerHTML;
      }
      scriptNode.parentNode.removeChild(scriptNode);
      document.body.appendChild(script);
    });
  };

  const submitHandler = (e) => {
    resetErrorMessage();
    fetchContent()
      .then((html) => {
        const main = document.querySelector("main");
        main.innerHTML = normalizeAdminUrls(html);
        evaluateScripts(main);
        enhance(main, ENHANCERS);
      })
      .catch(setErrorMessage);
  };

  return {
    init() {
      form.addEventListener("submit", preventingDefault(submitHandler));
    },
  };
};

export const enhancer = (form) => {
  const passwordForm = PasswordProtectionForm(form);
  passwordForm.init();
};
