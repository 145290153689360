/**
 * Loads the appropriate resolution for autoplaying video's,
 * as used in the page header
 */

import { getDocWidth } from "./responsive";
import { enhancer as objectFitEnhancer } from "./object-fit";

function getVideoWidth() {
  const docWidth = getDocWidth();

  switch (true) {
    case docWidth <= 480:
      return 640;
    case docWidth <= 760:
      return 960;
    case docWidth <= 1024:
      return 1280;
    default:
      return 1920;
  }
}

const setAndPlayVideo = (container) => {
  const size = getVideoWidth();
  const video = document.createElement("video");
  const autoplay = container.getAttribute("data-autoplay");

  if (autoplay === "true") {
    video.setAttribute("autoplay", "");
  } else {
    video.setAttribute("controls", "");
  }

  video.setAttribute("loop", "");
  video.setAttribute("muted", "");
  video.setAttribute("preload", "auto");
  video.setAttribute("playsinline", "");
  video.setAttribute("webkit-playsinline", "");
  video.setAttribute("poster", container.getAttribute("data-poster"));
  video.setAttribute("src", container.getAttribute(`data-src-${size}`));

  video.muted = true;
  video.load();

  const playVideo = () => {
    container.appendChild(video);
    objectFitEnhancer(video);
    if (autoplay === "true") {
      video.play();
      container.setAttribute("data-state", "playing");
      video.removeEventListener("canplaythrough", playVideo, false);
    }
  };

  const pauseVideo = () => {
    video.pause();
    container.setAttribute("data-state", "paused");
    video.removeEventListener("canplaythrough", playVideo, false);
  };

  if (video.readyState > 3) {
    playVideo();
    return;
  }
  video.addEventListener("canplaythrough", playVideo, false);

  // Toggle playing states
  const toggleButton = container.querySelector(".js-video-toggle");
  toggleButton.addEventListener("click", (e) => {
    if (container.getAttribute("data-state") === "playing") {
      pauseVideo();
      toggleButton.textContent = toggleButton.getAttribute("data-play-label");
      toggleButton.setAttribute("aria-pressed", true);
    } else {
      playVideo();
      toggleButton.textContent = toggleButton.getAttribute("data-pause-label");
      toggleButton.setAttribute("aria-pressed", false);
    }
  });
};

export const enhancer = (container) => {
  setAndPlayVideo(container);
};
