const getDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
};

/**
 * Push the event to the DataLayer, and log if eligible.
 */
export const pushEvent = (data) => {
  getDataLayer().push(data);
  if (window.GOOGLE_TAG_MANAGER_DEBUG) {
    if (data.event && data.event_tracking) {
      console.log("Tracking event:", data.event);
      if (typeof console.table === "function") {
        console.table({ event: data.event, ...data.event_tracking });
      }
    } else {
      console.log("Tracking event:", data);
    }
  }
};

/**
 * Track the event in the specified DataLayer format.
 */
export const trackEvent = ({
  type,
  category,
  action,
  label,
  value,
  nonInteraction = false,
}) => {
  if (!type || !category || !action) {
    console.error("Missing arguments in trackEvent.");
    return;
  }
  pushEvent({
    event: type,
    event_tracking: {
      category,
      action,
      label,
      value,
      "non-interaction": nonInteraction,
    },
  });
};

/**
 * Get attributes from the given element.
 */
const getAttributes = (el) => {
  const attributes = {
    type: el.getAttribute("data-event-type"),
    category: el.getAttribute("data-event-category"),
    action: el.getAttribute("data-event-action"),
    label: el.getAttribute("data-event-label"),
    value: el.getAttribute("data-event-value"),
  };
  // Only add the `nonInteraction` property if explicitly specified.
  if (el.getAttribute("data-event-non-interaction")) {
    attributes.nonInteraction =
      el.getAttribute("data-event-non-interaction") === "true";
  }
  return attributes;
};

/**
 * Click handler.
 */
export const handler = (el, e) => trackEvent(getAttributes(el));

/**
 * Form enhancer.
 */
export const enhancer = (form) => {
  if (form.nodeName.toLowerCase() !== "form") {
    console.log(`I don't know how to enhance non-forms.`);
  }
  form.addEventListener("submit", (e) => {
    if (form.checkValidity()) {
      trackEvent(getAttributes(form));
    }
  });
};
