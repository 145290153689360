import { matchesBreakpoint } from "./responsive";

const repositionPostLabel = (container, title, label, arrow) => {
  if (matchesBreakpoint("small")) {
    container.insertBefore(label, title);
  } else {
    container.insertBefore(label, arrow);
  }
};

export const enhancer = (el) => {
  const container = el.querySelector(".js-post-container");
  const title = el.querySelector(".js-post-title");
  const label = el.querySelector(".js-post-label");
  const arrow = el.querySelector(".js-post-arrow");
  if (!label) {
    return;
  }

  repositionPostLabel(container, title, label, arrow);
  window.addEventListener("resize", (e) =>
    repositionPostLabel(container, title, label, arrow)
  );
  window.addEventListener("orientationchange", (e) =>
    repositionPostLabel(container, title, label, arrow)
  );
};
