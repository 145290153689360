import { debounce } from "@grrr/utils";
import { matchesBreakpoint } from "./responsive";

const LISTING_SELECTOR = ".js-fp-site-listing";
const PLACEHOLDER_SELECTOR = ".js-fp-site-listing-placeholder";
const CONTAINER_SELECTOR = ".js-fp-site-listing-container";

const appendToMobilePlaceholder = (placeholder, listing) =>
  placeholder.appendChild(listing);
const appendToContainer = (container, listing) =>
  container.appendChild(listing);

/**
 * Adjust DOM position when resize occurs.
 */
const resizeHandler = (container, placeholder, listing) => {
  if (matchesBreakpoint("medium")) {
    appendToContainer(container, listing);
  } else {
    appendToMobilePlaceholder(placeholder, listing);
  }
};

export const enhancer = (el) => {
  const listing = el.querySelector(LISTING_SELECTOR);
  const placeholder = el.querySelector(PLACEHOLDER_SELECTOR);
  const container = el.querySelector(CONTAINER_SELECTOR);

  if (!matchesBreakpoint("medium")) {
    appendToMobilePlaceholder(placeholder, listing);
  }
  window.addEventListener("resize", (e) => {
    debounce(resizeHandler(container, placeholder, listing), 500);
  });
};
