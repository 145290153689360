const HTML_ACTIVE_CLASS = "page-has-expanded-site-nav";
const HEADER_NAV_EXPANDED_CLASS = "site-header--expanded";
const HEADER_NAV_COLLAPSED_CLASS = "site-header--collapsed";

const toggleNavClasses = () => {
  const nav = document.querySelector(".js-site-header");
  const shouldHide = nav.classList.contains(HEADER_NAV_EXPANDED_CLASS);

  nav.classList.toggle(HEADER_NAV_EXPANDED_CLASS, !shouldHide);
  nav.classList.toggle(HEADER_NAV_COLLAPSED_CLASS, shouldHide);
  document.documentElement.classList.toggle(HTML_ACTIVE_CLASS, !shouldHide);
};

const trapFocus = (el) => {
  const focusableEls = el.querySelectorAll(
    "a[href]:not([disabled]), button:not([disabled])"
  );
  const firstFocusableEl = focusableEls[0];
  const lastFocusableEl = focusableEls[focusableEls.length - 1];
  firstFocusableEl.focus();

  el.addEventListener("keydown", (e) => {
    const isTabPressed = e.key === "Tab" || e.keyCode === 9;
    if (!isTabPressed) {
      return;
    }
    if (e.shiftKey) {
      /* shift + tab */ if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
        e.preventDefault();
      }
    } else if (document.activeElement === lastFocusableEl) {
      firstFocusableEl.focus();
      e.preventDefault();
    }
  });
};

const toggleNavAttributes = (el) => {
  const nav = document.querySelector(".js-site-nav");
  const navOverlay = document.querySelector(".js-nav-overlay");

  el.setAttribute(
    "aria-expanded",
    el.getAttribute("aria-expanded") === "false"
  );
  nav.setAttribute("aria-hidden", nav.getAttribute("aria-hidden") === "false");
  navOverlay.setAttribute(
    "aria-hidden",
    navOverlay.getAttribute("aria-hidden") === "false"
  );
  trapFocus(navOverlay);
};

const hideOverlayOnEsc = (el, button) => {
  const nav = document.querySelector(".js-site-nav");
  const navOverlay = document.querySelector(".js-nav-overlay");

  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      el.setAttribute("aria-expanded", false);
      nav.setAttribute("aria-hidden", false);
      navOverlay.setAttribute("aria-hidden", true);
      button.focus();
    }
  });
};

export const toggleHandler = (el, e) => {
  e.preventDefault();
  toggleNavClasses();
  toggleNavAttributes(el);
  setTimeout(() => el.blur(), 0);
};

export const enhancer = (el) => {
  const openMenuButton = document.querySelector(".js-site-header__menu-button");
  hideOverlayOnEsc(el, openMenuButton);
};
