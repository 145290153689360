function fetchItem(index, data) {
  return data[index];
}

function updatePerson(el, index, data) {
  const TEMPLATE_SELECTOR = document.querySelector(
    ".js-remembrance-intro-template"
  );
  el.innerHTML = "";
  el.appendChild(TEMPLATE_SELECTOR.content.cloneNode(true));

  const namePlaceholder = el.querySelector(".js-remembrance-name");
  const yearPlaceholder = el.querySelector(".js-remembrance-year");
  const lineOnePlaceholder = el.querySelector(".js-remembrance-line-1");
  const lineTwoPlaceholder = el.querySelector(".js-remembrance-line-2");
  const lineThreePlaceholder = el.querySelector(".js-remembrance-line-3");
  const imagePlaceholder = el.querySelector(".js-remembrance-image");

  const item = fetchItem(index, data);

  namePlaceholder.innerHTML = item.title;
  if (item.birth) {
    yearPlaceholder.innerHTML = `(${item.birth})`;
  }
  if (item.rank_full) {
    lineOnePlaceholder.innerHTML = item.rank_full;
  }
  if (item.ship) {
    lineTwoPlaceholder.innerHTML = item.ship;
  }
  if (item.birth) {
    lineThreePlaceholder.innerHTML = `${item.age} jaar geworden`;
  }

  if (item.image) {
    const image = document.createElement("img");
    image.setAttribute("src", item.image);
    image.setAttribute("alt", "");
    imagePlaceholder.appendChild(image);
  }
}

function getCurrentIndex(data) {
  // I don't really understand this algorithm, but I guess this is the same
  // algorithm used in the projector. So the same person is remembered at the same time.
  const tsInSeconds = new Date().getTime() / 1000;
  const currentIndex =
    Math.floor((tsInSeconds % (data.length * 120)) / 120) - 1;
  return currentIndex;
}

function maybeUpdatePerson(container, data) {
  return () => {
    const tsInSeconds = new Date().getTime() / 1000;
    if (Math.floor(tsInSeconds % 120) === 0) {
      updatePerson(container, getCurrentIndex(data), data);
    }
  };
}

export const enhancer = (el) => {
  const remembranceItems = window.remembranceJson;
  setInterval(maybeUpdatePerson(el, remembranceItems), 0);
  updatePerson(el, getCurrentIndex(remembranceItems), remembranceItems);
};
