/* eslint-disable no-use-before-define, no-nested-ternary, no-return-assign */

import prefix from "prefix";
import { debounce, htmlToElement, memoize } from "@grrr/utils";
import { getDocWidth, matchesBreakpoint } from "./responsive";
import { trackEvent } from "./gtm-event";
import { clamp } from "./util";

import SmoothVirtualScroll from "./smooth-virtual-scroll";

const SCROLLER_SELECTOR = ".js-scroller";
const CARD_SELECTOR = ".js-card";
const CARD_TITLE_SELECTOR = ".js-card-title";
const ANNOUNCER_SELECTOR = ".js-announcer";
const VIDEO_ATTRIBUTE = "data-video";
const PREVIEW_ATTRIBUTE = "data-preview";

const SCROLL_DURATION_BASE = 500;
const SCROLL_DURATION_INCREMENT = 250;
const SCROLL_EASE = `cubic-bezier(0.645, 0.045, 0.355, 1)`; // easeInOutCubic

const DEBUG = false; // Toggle debug styling and meta info

/**
 * A card scroller with corresponding content.
 *
 * Can be invoked in 'full' mode, or in a more lightweight 'preview' mode until
 * switched to full.
 *
 * @TODO Maybe revert to native (fake) scroll for mobile with scroll-snapping?
 * @TODO Disable autoplay for eligible users?
 */
const DiscoveryScroller = (container) => {
  const DUPLICATION_COUNT = parseInt(
    container.getAttribute("data-duplication-count"),
    10
  );

  const scrollEl = container.querySelector(SCROLLER_SELECTOR);
  const announceEl = container.querySelector(ANNOUNCER_SELECTOR);
  const cards = container.querySelectorAll(CARD_SELECTOR);

  const items = [];

  let isInPreviewMode;
  let isTransitioning;
  let trackingEnabled = false;

  const prefixed = {
    transform: prefix("transform"),
    transformOrigin: prefix("transformOrigin"),
    transition: prefix("transition"),
    transitionDelay: prefix("transitionDelay"),
    opacity: prefix("opacity"),
  };

  /**
   * SmoothVirtualScroll instance.
   */
  const virtualScroll = SmoothVirtualScroll({
    direction: "both",
    bounds: {
      left: 0,
      right: scrollEl.scrollWidth - getDocWidth(),
    },
    easing: 0.15,
    virtualScrollOptions: {
      el: window,
    },
  });

  /**
   * Scroll 'width' and 'position' helpers.
   */
  const getScrollWidth = () => scrollEl.scrollWidth;
  const setScrollWidth = (width) => (scrollEl.style.width = `${width}px`);
  const resetScrollWidth = () => (scrollEl.style.width = ``);
  const resetScrollTransform = () => (scrollEl.style[prefixed.transform] = ``);

  /**
   * Get the right DOMMatrix.
   */
  const getDomMatrix = memoize(() => {
    const methods = [
      "DOMMatrixReadOnly",
      "DOMMatrix",
      "WebKitCSSMatrix",
      "MSCSSMatrix",
    ];
    return methods.find((method) => typeof window[method] === "function");
  });

  /**
   * Get current 'scroll position' from the transformed `scrollEl`.
   */
  const getScrollPosition = () => {
    const style = window.getComputedStyle(scrollEl)[prefixed.transform];
    const matrix = new window[getDomMatrix()](style);
    return matrix.m41 * -1; // inverted x transform
  };

  /**
   * Item object helpers.
   */
  const getCurrentItem = () => items.find((item) => item.isCurrent);
  const getCurrentItemIndex = () => getCurrentItem().index;
  const getItemByCard = (card) => items.find((item) => item.card === card);

  /**
   * Card element helpers.
   */
  const getCurrentCard = () => getCurrentItem().card;
  const getCardContainer = (card) => card.parentNode;
  const getCardWidth = (card) =>
    getCardContainer(card).getBoundingClientRect().width;
  const getCardBounds = (card) =>
    getCardContainer(card).getBoundingClientRect();

  /**
   * Arrow key helpers.
   */
  const isLeftKey = (e) => (e.key && e.key === "ArrowLeft") || e.keyCode === 37;
  const isUpKey = (e) => (e.key && e.key === "ArrowUp") || e.keyCode === 38;
  const isRightKey = (e) =>
    (e.key && e.key === "ArrowRight") || e.keyCode === 39;
  const isDownKey = (e) => (e.key && e.key === "ArrowDown") || e.keyCode === 40;

  /**
   * Track settled current items.
   */
  const trackItemDebounced = debounce(() => {
    trackEvent({
      type: "content_engaged",
      category: "content",
      action: "engaged",
      label: "Ontdekpagina scroll-item geactiveerd",
    });
  }, 500);

  /**
   * Announce current item to screenreaders (debounced).
   */
  const anounceCurrentDebounced = debounce(
    (content) => (announceEl.textContent = content),
    150
  );

  /**
   * Mark current content item (debounced).
   */
  const setCurrentContentDebounced = debounce((content) => {
    content.setAttribute("aria-current", "true");
    content.setAttribute("tabindex", "0");
  }, 50);

  /**
   * Create muted video element.
   */
  const createVideo = (src) => {
    const video = htmlToElement(`
      <video src="${src}" loop muted autoplay playsinline webkit-playsinline></video>
    `);
    video.muted = true;
    return video;
  };

  /**
   * Add, play or pause video in the current item.
   */
  const toggleVideo = (item) => {
    let video = item.card.querySelector("video");
    if (video && item.isCurrent) {
      video.play();
    } else if (video && !video.paused) {
      video.pause();
    } else if (item.isCurrent) {
      video = createVideo(item.video);
      item.card.appendChild(video);
      const playVideo = () => {
        video.play();
        video.removeEventListener("canplaythrough", playVideo, false);
        item.card.setAttribute("data-video-loaded", "true");
      };
      if (video.readyState > 3) {
        playVideo();
      } else {
        video.addEventListener("canplaythrough", playVideo, false);
        video.load();
      }
    }
  };

  /**
   * Toggle videos after scrolling has ended.
   */
  const toggleVideosDebounced = debounce(
    () => items.filter((item) => item.video).forEach(toggleVideo),
    50
  );

  /**
   * Calculate which item is closest to the given center.
   */
  const closestItemToCenter = (center) => {
    const centerMatch = items.reduce((acc, item) => {
      return Math.abs(item.center - center) < Math.abs(acc - center)
        ? item.center
        : acc;
    }, 0);
    return items.find((item) => item.center === centerMatch);
  };

  /**
   * Debug helper.
   */
  const updateCardDebugInfo = ({ card, xOrigin, ratio, y }) => {
    let box = card.querySelector(".js-debug-box");
    if (!box) {
      box = htmlToElement(
        `<div class="discovery-scroller__debug js-debug-box"></div>`
      );
      card.appendChild(box);
    }
    box.innerHTML = `
      x-origin: ${Math.round(xOrigin * 100) / 100}<br/>
      ratio: ${Math.round(ratio * 100) / 100}<br/>
      y: ${Math.round(y * 100) / 100}<br/>
    `;
  };

  /**
   * Calculate card styles based on scroll position.
   *
   * Note: all positions are cached to prevent any usage of `getBoundingClientRect()`,
   * which will prevent layout trashing. Although in practice this didn't seem to
   * affect performance that much in at least Chrome...
   */
  const calculateCardStyles = ({ item, targetX }) => {
    const screenWidth = getDocWidth();

    // Set a fixed transform when offscreen with a margin of 'one screen'.
    if (
      item.left < targetX - screenWidth ||
      item.left > targetX + screenWidth * 2
    ) {
      return {
        [prefixed.transform]: `translate(0, 100%) scale(0.1)`,
        [prefixed.transformOrigin]: `50% bottom`,
        [prefixed.opacity]: `0.25`,
      };
    }

    const scrollCenter = targetX + screenWidth / 2;
    const offset = item.center - scrollCenter;
    const offsetIndexFloat = Math.abs(offset / item.width);

    // Calculate item ratio, based on offset.
    // Results in: ..., 0, 0.25, 0.5, 0.75, 1, 0.75, 0.5, 0.25, 0, ...
    const ratio = clamp(1 - offsetIndexFloat * 0.2, 0, 1);

    // Calculate the horizontal `transform-origin` percentage per offsetted pixel.
    // The percentage will be 50% per item, so the screenwidth is divided by the total
    // percentage.
    const xOriginPercentage = screenWidth / ((screenWidth / item.width) * 50);

    // Calculate the x `transform-origin`. The base is `50%` (aka. `center`).
    // Results in: ..., 200%, 150%, 100%, 50%, 0%, -50%, -100%, ...
    const xOrigin = (offset * -1) / xOriginPercentage + 50;

    // Calculate progressive y offset.
    // Results in: ..., 22.5%, 10%, 2%, 0%, 2%, 10%, 22.5%, ...
    const y =
      offsetIndexFloat *
      5 *
      (offsetIndexFloat / (matchesBreakpoint("small") ? 1 : 0.5));

    if (DEBUG) {
      updateCardDebugInfo({ card: item.card, xOrigin, ratio, y });
    }

    return {
      [prefixed.transform]: `translate(0%, ${y}%) scale(${ratio})`,
      [prefixed.transformOrigin]: `${xOrigin}% bottom`,
      [prefixed.opacity]: ratio,
    };
  };

  /**
   * Calculate and apply styles to the card.
   */
  const paintCard = ({ item, targetX }) => {
    const styles = calculateCardStyles({ item, targetX });
    Object.entries(styles).forEach(
      ([key, value]) => (item.card.style[key] = value)
    );
  };

  /**
   * Pain the current item and update attributes/properties.
   */
  const paintItem = ({ item, isMatch, targetX }) => {
    item.isCurrent = isMatch;
    item.card.setAttribute("aria-current", isMatch ? "true" : "");
    // The current content will be shown via `setCurrentContentDebounced`.
    item.content.setAttribute("aria-current", "");
    item.content.setAttribute("tabindex", "-1");
    if (isMatch && !isInPreviewMode) {
      anounceCurrentDebounced(
        item.content.querySelector(CARD_TITLE_SELECTOR).textContent
      );
      setCurrentContentDebounced(item.content);
      if (trackingEnabled) {
        trackItemDebounced();
      }
    }
    paintCard({ item, targetX });
  };

  /**
   * Find the current item and paint individual items.
   */
  const paintItems = (targetX) => {
    const match = closestItemToCenter(targetX + getDocWidth() / 2);
    items.forEach((item) =>
      paintItem({ item, isMatch: item === match, targetX })
    );
    if (!isInPreviewMode) {
      toggleVideosDebounced();
    }
  };

  /**
   * Calculate if left/right bounds are hit, and calculate reset position 'on the other side'.
   */
  const calculateBoundResetPosition = (targetX) => {
    const screenCenter = getDocWidth() / 2;
    const leftBound = items[DUPLICATION_COUNT - 1].center - screenCenter;
    const leftReset = items[DUPLICATION_COUNT].center - screenCenter;
    const rightBound =
      items[items.length - DUPLICATION_COUNT].center - screenCenter;
    const rightReset =
      items[items.length - (DUPLICATION_COUNT + 1)].center - screenCenter;

    if (targetX <= leftBound) {
      return rightReset;
    }
    if (targetX >= rightBound) {
      return leftReset;
    }

    return null;
  };

  /**
   * Paint items during scroll container transitions.
   *
   * Note: 'ending the transition' was first handled by a 'transitionend' event
   * but this caused a few bugs. So now we simply compare the target and last
   * x positions to see if the transition has ended.
   *
   * @TODO check if this makes sense (or should we have a global rAF?).
   */

  let transRAF;

  const paintItemsDuringContainerTransition = () => {
    cancelAnimationFrame(transRAF);

    const endTransition = () => {
      isTransitioning = false;
      const position = getScrollPosition();
      virtualScroll.setCurrent(position);
      virtualScroll.setTarget(position);
    };

    let lastX;
    const update = () => {
      const targetX = getScrollPosition();
      const resetPosition = calculateBoundResetPosition(targetX);
      if (resetPosition) {
        endTransition();
        resetScrollPosition(resetPosition);
      } else if (targetX === lastX) {
        paintItems(targetX);
        endTransition();
      } else {
        isTransitioning = true;
        paintItems(targetX);
        transRAF = requestAnimationFrame(() => {
          update();
          // Tiny hack to make 'more sure' the next targetX check will have a new computed value.
          // If not, it might take a few frames before it reaches `targetX === lastX`.
          // But that's fine.
          window.setTimeout(() => (lastX = targetX), 50);
        });
      }
    };

    transRAF = requestAnimationFrame(update);
  };

  /**
   * Scroll container and paint items when transitioning.
   */
  const paintContainer = (targetX, duration) => {
    if (isTransitioning) {
      return;
    }
    const resetPosition = calculateBoundResetPosition(targetX);
    if (duration) {
      scrollEl.style[prefixed.transition] = `transform ${
        duration / 1000
      }s ${SCROLL_EASE}`;
      paintItemsDuringContainerTransition();
    } else if (resetPosition) {
      resetScrollPosition(resetPosition);
    } else {
      scrollEl.style[prefixed.transition] = ``;
    }
    scrollEl.style[prefixed.transform] = `translate3d(${targetX * -1}px, 0, 0)`;
    paintItems(targetX);
  };

  /**
   * Scroll to x-position.
   */
  const scrollToPosition = (targetX, duration) =>
    paintContainer(targetX, duration);

  /**
   * Scroll to a certain item.
   */
  const scrollToItem = (item, duration) =>
    scrollToPosition(item.center - getDocWidth() / 2, duration);

  /**
   * Debounced 'snap' to current item.
   */
  const scrollToCurrentItemDebounced = debounce(
    () => scrollToItem(getCurrentItem(), SCROLL_DURATION_BASE / 2),
    100
  );

  /**
   * Reset to a certain scroll position.
   */
  const resetScrollPosition = (targetX) => {
    scrollToPosition(targetX);
    virtualScroll.setCurrent(targetX);
    virtualScroll.setTarget(targetX);
  };

  /**
   * Handle card clicks (from the handler).
   */
  const cardClickHandler = (card) => {
    const cardWidth = getCardWidth(getCurrentCard());
    const currentX = getScrollPosition();
    const targetX = getItemByCard(card).center - getDocWidth() / 2;
    const increment = Math.abs((targetX - currentX) / cardWidth) - 1;
    const duration =
      SCROLL_DURATION_BASE + increment * SCROLL_DURATION_INCREMENT;
    scrollToPosition(targetX, duration);
  };

  /**
   * Handle arrow key events.
   */
  const keyDownHandler = (e) => {
    if (isUpKey(e) || isLeftKey(e)) {
      e.preventDefault();
      e.stopPropagation();
      scrollToItem(items[getCurrentItemIndex() - 1], SCROLL_DURATION_BASE);
    }
    if (isDownKey(e) || isRightKey(e)) {
      e.preventDefault();
      e.stopPropagation();
      scrollToItem(items[getCurrentItemIndex() + 1], SCROLL_DURATION_BASE);
    }
  };

  /**
   * Center slider and mark the center item as 'current'.
   */
  const centerSlider = () => {
    const centerItem = items[Math.floor(items.length / 2)];
    centerItem.isCurrent = true;
    scrollToItem(centerItem);
    const position = getScrollPosition();
    virtualScroll.setCurrent(position);
    virtualScroll.setTarget(position);
  };

  /**
   * Calculate new center points after rezise event and re-paint all items.
   */
  const recalculateBounds = (e) => {
    // Reset slider width.
    resetScrollWidth();
    resetScrollTransform();

    // Update slider width and virtual scroll bounds.
    const scrollWidth = getScrollWidth();
    setScrollWidth(scrollWidth);
    virtualScroll.setBounds({
      left: 0,
      right: scrollWidth - getDocWidth(),
    });

    // Update item dimensions and bounds.
    items.forEach((item, index) => {
      const bounds = getCardBounds(item.card);
      items[index] = {
        ...item,
        left: bounds.width * index,
        center: bounds.width * index + bounds.width / 2,
        width: bounds.width,
        isCurrent: false,
      };
    });

    // Center the slider.
    centerSlider();
  };

  /**
   * The `item` object.
   * @TODO partially merge this with `resizeHandler`?
   */
  const createItemObject = (card, index) => {
    const bounds = getCardBounds(card);
    return {
      index,
      left: bounds.width * index,
      center: bounds.width * index + bounds.width / 2,
      width: bounds.width,
      card,
      content: container.querySelector(card.getAttribute("href")),
      video: card.getAttribute(VIDEO_ATTRIBUTE),
      isCurrent: false,
    };
  };

  /**
   * Bind input listeners (scroll and keyboard).
   */
  const attachInputListeners = () => {
    // Listener for virtual scroll events.
    virtualScroll.on("scroll", ({ position, change }) => {
      if (!isTransitioning) {
        scrollEl.style[prefixed.transition] = ``; // @TODO
        paintContainer(position);
        scrollToCurrentItemDebounced();
      }
    });

    // Listener for arrow key events.
    window.addEventListener("keydown", keyDownHandler, { passive: false });
  };

  /**
   * Load images. These are set as data-attributes, becuase we don't want to load
   * them all when the scroller is in preview mode.
   */
  const loadImages = (targetItems = items) => {
    targetItems.forEach((item) => {
      const image = item.card.querySelector("img");
      image.src = image.getAttribute("data-src");
      image.srcset = image.getAttribute("data-srcset");
    });
  };

  /**
   * Switch from 'preview' to 'full' mode.
   */
  const switchToFull = () => {
    isInPreviewMode = false;
    attachInputListeners();
    recalculateBounds();
    centerSlider();
    loadImages();
    scrollEl.setAttribute("tabindex", "0");

    // Enable tracking (after all programmatic debounced changes are settled).
    window.setTimeout(() => {
      trackingEnabled = true;
    }, 500);
  };

  /**
   * Calculate initial visible items (based on 'desktop' layout).
   */
  const getInitialVisibleItems = () => {
    const middle = items.length / 2;
    const offset = 4; // Seven is the maximum amount of items ever visible.
    return items.filter(
      (item, index) => index < middle + offset && index > middle - offset
    );
  };

  return {
    init() {
      // Simply do not initialize when the item count is lower than the duplication
      // count. Note that they're pre- and appended in the template, so the total count
      // should at least be three times the duplication count.
      if (cards.length < DUPLICATION_COUNT * 3) {
        console.warn("Too little discovery scroller items found.");
        return;
      }

      // Set mode and debug state.
      isInPreviewMode = container.getAttribute(PREVIEW_ATTRIBUTE) === "true";
      container.setAttribute("data-debug", DEBUG);
      scrollEl.setAttribute("tabindex", isInPreviewMode ? "" : "0");

      // Set fixed width for scroll element, so we force it to overflow.
      setScrollWidth(getScrollWidth());

      // Add items to items array and attach click function.
      [...cards].forEach((card, index) => {
        items.push(createItemObject(card, index));
        card.click = cardClickHandler;
      });

      // Set initial slider position and paint items.
      centerSlider();

      // Attach listeners.
      window.addEventListener("resize", debounce(recalculateBounds, 500));

      // Check if the scroller is invoked in preview mode (e.g. used on homepage).
      // If so, initialize it in a more lightweight mode until the full mode is invoked.
      if (isInPreviewMode) {
        container.switchToFull = switchToFull;
        loadImages(getInitialVisibleItems());
      } else {
        attachInputListeners();
        loadImages();
      }

      // Set initial transition delay and show the whole thing.
      getInitialVisibleItems().forEach((item, index) => {
        item.card.parentNode.style[prefixed.transitionDelay] = `${
          index * 0.1
        }s`;
      });
      window.setTimeout(
        () => container.setAttribute("data-loaded", "true"),
        1000 / 60
      );

      // Enable tracking (after all programmatic debounced changes are settled).
      if (!trackingEnabled) {
        window.setTimeout(() => {
          trackingEnabled = true;
        }, 500);
      }
    },
  };
};

export const enhancer = (container) => {
  const discoveryScroller = DiscoveryScroller(container);
  discoveryScroller.init();
};

export const handler = (card, e) => {
  e.preventDefault();
  card.click(card);
};
