import { debounce } from "@grrr/utils";
import { matchesBreakpoint } from "./responsive";

const CONTENT_SELECTOR = ".js-fp-content";
const DISCOVERY_SELECTOR = ".js-fp-discovery";
const PLACEHOLDER_SELECTOR = ".js-fp-discovery-placeholder";
const OVERLAY_SELECTOR = ".js-fp-overlay";
const BUTTON_SELECTOR = ".js-fp-button";
const SCROLLER_SELECTOR = '[data-enhancer="discoveryScroller"]';

const TITLE_ATTRIBUTE = "data-site-title";
const PAGE_ATTRIBUTE = "data-discovery-page";
const LOADED_ATTRIBUTE = "data-loaded";

const FrontPage = (container) => {
  const content = container.querySelector(CONTENT_SELECTOR);
  const discovery = container.querySelector(DISCOVERY_SELECTOR);
  const placeholder = container.querySelector(PLACEHOLDER_SELECTOR);
  const overlay = container.querySelector(OVERLAY_SELECTOR);
  const buttonContainer = container.querySelector(BUTTON_SELECTOR);
  const scroller = container.querySelector(SCROLLER_SELECTOR);

  /**
   * Switch to discovery mode.
   * Replaces the current front page with a representation of the discovery page.
   */
  const switchToDiscovery = () => {
    content.setAttribute("aria-hidden", "true");
    buttonContainer.setAttribute("aria-hidden", "true");
    discovery.setAttribute("data-hidden", "false");
    overlay.setAttribute("data-hidden", "true");

    // Desktop.
    if (matchesBreakpoint("medium")) {
      window.setTimeout(() => {
        [...container.querySelectorAll('[data-preview="true"]')].forEach(
          (el) => {
            el.setAttribute("data-preview", "false");
          }
        );
        content.style.visibility = "hidden";
        document.body.setAttribute("data-layout", "fullscreen");
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        scroller.switchToFull();
      }, 700);

      // Mobile.
    } else {
      // Step 1.
      placeholder.setAttribute("data-state", "preparing");
      placeholder.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      // Step 2.
      window.setTimeout(() => {
        placeholder.setAttribute("data-state", "transitioning");
        container
          .querySelector('[data-preview="true"]')
          .setAttribute("data-preview", "false");
      }, 500);

      // Step 3.
      window.setTimeout(() => {
        placeholder.setAttribute("data-state", "full");
        [...container.querySelectorAll('[data-preview="true"]')].forEach(
          (el) => {
            el.setAttribute("data-preview", "false");
          }
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        document.body.setAttribute("data-layout", "fullscreen");
        scroller.switchToFull();
      }, 1050);
    }

    // Add the page to the session history.
    const title = `Ontdek | ${container.getAttribute(TITLE_ATTRIBUTE)}`;
    window.history.pushState({}, title, container.getAttribute(PAGE_ATTRIBUTE));
    document.title = title;

    // Listen for 'back' event and redirect to previous page (the front page).
    window.addEventListener("popstate", (e) => {
      e.preventDefault();
      window.location.replace(e.target.location.href);
    });
  };

  /**
   * Discovery DOM parent swappers.
   */
  const appendToMobilePlaceholder = () => placeholder.appendChild(discovery);
  const appendToContainer = () => container.appendChild(discovery);

  /**
   * Adjust DOM position when resize occurs.
   */
  const resizeHandler = (e) => {
    if (matchesBreakpoint("medium")) {
      appendToContainer();
    } else {
      appendToMobilePlaceholder();
    }
  };

  /**
   * Handle clicks on switch button and overlay.
   */
  const switchClickHandler = (e) => {
    e.preventDefault();
    switchToDiscovery();
  };

  return {
    init() {
      overlay.addEventListener("click", switchClickHandler);
      buttonContainer
        .querySelector("a")
        .addEventListener("click", switchClickHandler);

      // Adjust the DOM position based on breakpoint.
      if (!matchesBreakpoint("medium")) {
        appendToMobilePlaceholder();
      }
      window.addEventListener("resize", debounce(resizeHandler, 500));

      // Show the front page.
      window.setTimeout(
        () => container.setAttribute(LOADED_ATTRIBUTE, "true"),
        100
      );
    },
  };
};

export const enhancer = (container) => {
  const frontPage = FrontPage(container);
  frontPage.init();
};
