/* eslint-disable max-len, no-return-assign, no-use-before-define */

import VirtualScroll from "virtual-scroll";

/**
 * Create a smooth/eased scroll based on VirtualScroll.
 */
const SmoothVirtualScroll = ({
  direction = "both",
  easing = 0.075,
  bounds,
  virtualScrollOptions = {},
} = {}) => {
  const rAF = {
    listener: null,
    canceled: true,
  };

  const state = {
    current: 0,
    target: 0,
    bounds,
  };

  /**
   * VirtualScroll instance.
   */
  const virtualScroll = new VirtualScroll({
    el: window,
    mouseMultiplier: 0.2,
    touchMultiplier: 2,
    firefoxMultiplier: 50,
    useKeyboard: false,
    useTouch: true,
    ...virtualScrollOptions,
  });

  /**
   * Event listener helpers.
   */
  const eventListeners = {};
  const addEventListener = (type, fn) =>
    (eventListeners.type = [...(eventListeners.type || []), fn]);
  const dispatchEvent = (type, ...args) =>
    eventListeners.type
      ? eventListeners.type.forEach((fn) => fn(...args))
      : undefined;

  /**
   * Set active requestAnimationFrame.
   */
  const setRequestAnimationFrame = () => {
    rAF.canceled = false;
    rAF.listener = requestAnimationFrame(updateCurrentWithEasing);
  };

  /**
   * Cancel active requestAnimationFrame.
   */
  const cancelRequestAnimationFrame = () => {
    rAF.canceled = true;
    rAF.listener = cancelAnimationFrame(rAF.listener);
  };

  /**
   * Calculate eased new current position based on the new target.
   */
  const updateCurrentWithEasing = () => {
    if (rAF.canceled) {
      return;
    }

    // Trigger another update. Note that we do this early, since it might get
    // cancelled later on.
    setRequestAnimationFrame();

    // Calculate diff and delta.
    const diff = state.target - state.current;
    const delta = diff * easing;

    // Update the current state, and cancel it when eased scrolling ends.
    if (Math.abs(diff) < 0.1) {
      cancelRequestAnimationFrame();
      state.current = state.target;
    } else {
      state.current += delta;
    }

    // Dispatch the event with new scroll position and changed amount.
    dispatchEvent("scroll", {
      position: state.current,
      change: delta,
    });
  };

  /**
   * Set target position with auto clamping when bounds are defined.
   */
  const setTarget = (target) => {
    if (state.bounds && Object.keys(state.bounds).length) {
      state.target = Math.round(
        Math.max(state.bounds.left, Math.min(target, state.bounds.right))
      );
    } else {
      state.target = target;
    }
    // Set requestAnimationFrame which will call updateCurrentWithEasing.
    if (!rAF.listener) {
      setRequestAnimationFrame();
    }
  };

  /**
   * Get event delta based on specified direction.
   * If no direction or `both` is defined, prefer `x` over `y` in case of
   * multidirectional events (e.g. a trackpad or 'Magic Mouse').
   */
  const getDelta = (e) => {
    switch (direction) {
      case "horizontal":
        return e.deltaX;
      case "vertical":
        return e.deltaY;
      default:
        return e.deltaX ? e.deltaX : e.deltaY;
    }
  };

  /**
   * VirtualScroll update event listener.
   */
  virtualScroll.on((e) => setTarget(state.target + getDelta(e) * -1));

  return {
    on: addEventListener,
    setCurrent(position) {
      state.current = position;
    },
    setTarget(position) {
      state.target = position;
    },
    setBounds({ left, right }) {
      state.bounds = { left, right };
    },
  };
};

export default SmoothVirtualScroll;
