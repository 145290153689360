import Cookies from "js-cookie";
import CookieConsent from "@grrr/cookie-consent";
import { trapFocus } from "@grrr/utils";
import { takeLast } from "@grrr/utils";
import { pushEvent } from "./gtm-event";

const HTML_ACTIVE_CLASS = "page-has-active-overlay";
const focusTrappedItem = {};

/**
 * Code for cookie consent popup
 */
const trapFocusOnItem = (trap, item) => {
  if (trap) {
    focusTrappedItem[item] = trapFocus(item);
  }
  if (!trap) {
    focusTrappedItem[item].release();
  }
};

const toggleOverlayClasses = () => {
  const cookieOverlay = document.querySelector(".js-cookie-consent-overlay");
  const cookieOverlayContent = document.querySelector(
    ".js-cookie-consent-overlay__content"
  );
  const shouldHide = cookieOverlay.getAttribute("data-hidden") === "false";

  document.documentElement.classList.toggle(HTML_ACTIVE_CLASS, !shouldHide);
  cookieOverlay.setAttribute("data-hidden", shouldHide);
  cookieOverlay.setAttribute("aria-hidden", shouldHide);
  cookieOverlayContent.setAttribute("aria-hidden", shouldHide);

  if (!shouldHide) {
    trapFocusOnItem(true, cookieOverlay);
  } else {
    trapFocusOnItem(false, cookieOverlay);
  }
};

const cookiesAreAccepted = () => {
  // Aria-hidden is false as default otherwise a screen reader will skip it,
  // even if you change the value with JS on load
  // So now the aria-hidden is made true with JS when cookies are accepted
  const cookieOverlay = document.querySelector(".js-cookie-consent-overlay");
  cookieOverlay.setAttribute("aria-hidden", true);
  cookieOverlay.setAttribute("data-hidden", true);
};

/**
 * Code for cookie consent package
 */
const changeOverlayAriaAttributes = (ariaElement, newAriaDescriptionId) => {
  ariaElement.setAttribute("aria-describedby", newAriaDescriptionId);
  ariaElement.removeAttribute("aria-labelledby");
};

const removeAriaAttributes = (element) => {
  // These attributes are on the parent element
  element.removeAttribute("aria-live");
  element.removeAttribute("aria-describedby");
  element.removeAttribute("aria-hidden");
  element.removeAttribute("role");
  element.removeAttribute("tabindex");
};

/**
 * This function checks the checkboxes of the active cookies,
 * because the cookies package only checks them on load (not when cookie values get updated).
 */
const checkActiveCookieCheckboxes = (cookiesModule) => {
  const cookiesState = window.CookieConsent.getPreferences();
  const checkboxes = cookiesModule.querySelectorAll(
    ".cookie-consent__option > input"
  );

  checkboxes.forEach((checkbox) => {
    const checkboxCookieType = checkbox.getAttribute("value");
    const cookieIsActive = cookiesState.find(
      (cookie) => cookie.id === checkboxCookieType && cookie.accepted === true
    );

    if (cookieIsActive) {
      checkbox.setAttribute("checked", true);
    }
  });
};

/**
 * Set focus to the first found element that matches the selector.
 */
const setFocusOnFirstFocusableElement = (container) => {
  const allFocusableElements = container.querySelectorAll(
    "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe"
  );
  const firstFocusableElement = allFocusableElements[0];

  if (!firstFocusableElement) return;
  firstFocusableElement.focus();
};

/**
 * Initialize the cookie consent and make it globally available.
 */
const initializeCookieConsent = () => {
  // Bail out if there is no Cookie Config.
  if (!window.COOKIE_CONSENT_CONFIG) {
    return;
  }

  // Construct and initialize the module.
  const cookieConsent = CookieConsent(window.COOKIE_CONSENT_CONFIG);
  // Make the object globally available.
  window.CookieConsent = cookieConsent;
};

const sendGTMCookies = (cookies) => {
  // Fire single GTM event with cookie preferences.
  const consentFeatures = cookies.reduce((acc, cookie) => {
    acc[cookie.id] = `${cookie.accepted}`;
    return acc;
  }, {});
  pushEvent({
    event: "cookieConsent",
    consentFeatures,
  });

  // Set actual cookies per type, used for fetch cross-subdomain cookie consent
  // via GTM (for example ticketing domain).
  cookies.forEach((cookie) => {
    const rootDomain = takeLast(2, window.location.hostname.split(".")).join(
      "."
    );
    Cookies.set(`cookieConsent_${cookie.id}`, `${cookie.accepted}`, {
      expires: 365,
      domain: rootDomain,
      secure: true,
    });
  });
};

/**
 * Enhancers and Handlers
 */
export const enhancer = (container) => {
  initializeCookieConsent();
  window.CookieConsent.on("update", sendGTMCookies);

  if (localStorage.getItem("cookie-consent-preferences")) {
    cookiesAreAccepted();
    return;
  }

  toggleOverlayClasses();
  setFocusOnFirstFocusableElement(container);
};

export const acceptCookiesHandler = (container) => {
  const acceptedCookies = window.COOKIE_CONSENT_CONFIG.cookies.map((cookie) => {
    return { id: cookie.id, accepted: true };
  });

  window.CookieConsent.updatePreference(acceptedCookies);

  toggleOverlayClasses();
};

export const selectCookiesHandler = (container) => {
  const cookieOverlay = document.querySelector(".js-cookie-consent-overlay");
  const cookieOverlayContent = document.querySelector(
    ".js-cookie-consent-overlay__content"
  );

  // Calls CookieConsent module and places it inside cookie-consent-overlay popup
  cookieOverlay.appendChild(window.CookieConsent.getDialog());
  // Hide the current section within the modal
  cookieOverlayContent.setAttribute("aria-hidden", "true");

  const cookieDialogModule = document.querySelector(".js-cookie-bar");
  const cookieDialogButton = document.querySelector(
    ".js-cookie-bar .cookie-consent__button"
  );
  const cookieDescription = document.querySelector(
    ".js-cookie-bar .cookie-consent__header"
  );

  checkActiveCookieCheckboxes(cookieDialogModule);
  // This function becomes redundant when the CookieConsent package gets updated
  removeAriaAttributes(cookieDialogModule);
  changeOverlayAriaAttributes(cookieOverlay, cookieDescription.id);
  trapFocusOnItem(false, cookieOverlay);
  trapFocusOnItem(true, cookieDialogModule);

  cookieDialogButton.addEventListener("click", () => toggleOverlayClasses(), {
    once: true,
  });

  // Set focus to the first input in the form.
  setFocusOnFirstFocusableElement(cookieDialogModule);
};

export const embedCookiesHandler = (container) => {
  // Update existing cookies to ensure optimal cookies are set.
  const { cookies } = window.COOKIE_CONSENT_CONFIG;
  const newCookies = cookies.map((cookie) =>
    cookie.id === "marketing"
      ? {
          ...cookie,
          accepted: true,
        }
      : {
          ...cookie,
          accepted: false,
        }
  );
  // Set cookies programmatically.
  window.CookieConsent.updatePreference(newCookies);
  window.CookieConsent.hideDialog();
};

/**
 * Open the cookie popup.
 */
export const showCookieDialogHandler = (container, event) => {
  // Most likely it's a link, so stop the current event.
  event.preventDefault();

  toggleOverlayClasses();
};
