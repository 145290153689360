/* eslint-disable operator-linebreak */
const FETCH_PARAMS = {
  method: "post",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
};

const ALERT_MESSAGE_SELECTOR = ".js-newsletter-form__alert-message";
const MAILCHIMP_MESSAGE_SELECTOR = ".js-newsletter-form__mailchimp-message";

const NewsletterSignup = (form) => {
  const emailInput = form.querySelector('[name="email"]');
  const alertElement = form.querySelector('[role="alert"]');
  const listIdInput = form.querySelector('[name="listId"]');

  const hideAlert = () => {
    emailInput.removeAttribute("aria-describedby");
    emailInput.removeAttribute("aria-invalid");
    alertElement.setAttribute("aria-hidden", "true");
    alertElement.firstElementChild.textContent = "";
    alertElement.firstElementChild.nextElementSibling.textContent = "";
  };

  const showAlert = (message, type, submessage) => {
    if (type === "error") {
      emailInput.setAttribute("aria-invalid", "true");
      emailInput.setAttribute("aria-describedby", alertElement.id);
    }
    alertElement.setAttribute("aria-hidden", "false");
    alertElement.setAttribute("data-type", type);
    alertElement.querySelector(ALERT_MESSAGE_SELECTOR).textContent = message;
    if (submessage) {
      alertElement.querySelector(MAILCHIMP_MESSAGE_SELECTOR).textContent =
        submessage;
    }
  };

  const subscribe = ({ action, data }) => {
    return new Promise((resolve, reject) => {
      fetch(action, { ...FETCH_PARAMS, body: JSON.stringify(data) }).then(
        (response) => {
          if (!response.ok) {
            return response.json().then((result) => reject(result.message));
          }
          return response.json().then(resolve);
        }
      );
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    hideAlert();
    subscribe({
      action: form.getAttribute("action"),
      data: {
        email: emailInput.value,
        listId: listIdInput.value,
      },
    })
      .then((response) => {
        const localizedMessage =
          window.NEWSLETTER_SUBSCRIBE_SNIPPETS.success_message;
        showAlert(localizedMessage, "success");
      })
      .catch((message) => {
        const localizedMessage =
          window.NEWSLETTER_SUBSCRIBE_SNIPPETS.error_message;
        showAlert(localizedMessage, "error", message);
      });
  };

  return {
    init() {
      form.addEventListener("submit", handleSubmit);
    },
  };
};

export const enhancer = (form) => {
  const signup = NewsletterSignup(form);
  signup.init();
};
