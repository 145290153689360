/**
 * Makes the bar on the campaign template sticky when it's out of view
 */
const options = {
  root: null,
  rootMargin: "50px",
  threshold: 0,
};
let footerHasBeenInView = false;

function callback(entries, observer) {
  const stickyBar = document.querySelector(".js-sticky-bar");

  entries.forEach((entry) => {
    const footerBlock =
      entry.target.getAttribute("data-footer-block") === "true";

    if (
      entry.isIntersecting &&
      stickyBar.classList.contains("js-sticky-bar--is-sticky") &&
      !footerBlock
    ) {
      stickyBar.classList.remove("js-sticky-bar--is-sticky");
    }
    if (!entry.isIntersecting && !footerBlock) {
      stickyBar.classList.add("js-sticky-bar--is-sticky");
    }

    // The footer block has a different if statement
    // because it should only trigger once it has been in view.
    // Otherwise it make the header sticky on page load
    // when the campaign header is in view
    if (entry.isIntersecting && footerBlock) {
      stickyBar.classList.remove("js-sticky-bar--is-sticky");
      footerHasBeenInView = true;
    }
    if (!entry.isIntersecting && footerBlock && footerHasBeenInView) {
      stickyBar.classList.add("js-sticky-bar--is-sticky");
    }
  });
}

const observer = new IntersectionObserver(callback, options);

export const enhancer = (container) => {
  observer.observe(container);
};
